<template>
	<div>
		<div class="booking-popup" :class="{ active: showBookingPopup }" @click="hideBookingPopup">
			<div class="booking-inner align-center">
				<h3>{{ $t('chooseHotel') }}</h3>
				<div class="booking-items">
					<a
						v-for="hotel in items"
						:key="hotel.ID"
						:href="`https://reservations.cubilis.eu/be/` + hotel.bookingID"
						target="_blank"
						class="reserve-now"
						@click="hideBookingPopup"
					>
						<picture>
							<source
								v-if="hotel.overviewImageWebp"
								:srcset="hotel.overviewImageWebp"
								type="image/webp"
							/>
							<source :srcset="hotel.overviewImage" />
							<img loading="lazy" :src="hotel.overviewImage" :alt="hotel.overviewImageAlt" />
						</picture>
						<span>{{ hotel.header }}</span>
					</a>
				</div>
			</div>
		</div>
		<nav v-click-outside="hideMenu" class="main fix-on-scroll" :class="{ active: showMenu }">
			<div class="row">
				<div class="columns column12 nav-column">
					<nuxt-link
						class="logo"
						:class="page.headingImage ? '' : 'black'"
						aria-label="Back to homepage"
						:to="localePath('/')"
					/>

					<div class="buttons">
						<div class="button button-cta reserve-now right" @click="toggleBookingPopup">
							<font-awesome-icon icon="fa-light fa-calendar-days" size="1x" />
							<span>{{ $t('bookNow') }}</span>
						</div>
						<div
							class="menu-button button right button-en"
							:class="{ active: showMenu }"
							@click="toggleMenu"
						>
							<font-awesome-icon v-show="showMenu" icon="fa-light fa-times" size="1x" />
							<font-awesome-icon v-show="!showMenu" icon="fa-light fa-bars" size="1x" />
							<span>{{ $t('menu') }}</span>
						</div>
					</div>
				</div>
				<div class="nav-container">
					<ul class="main-nav">
						<li
							v-for="item in topmenu[locale]"
							:key="item.filename"
							:class="{ 'has-dropdown': item.submenu.length }"
						>
							<nuxt-link :exact="item.slug === 'index'" :to="`${item.filename}`" @click="hideMenu">
								{{ item.header }}
							</nuxt-link>
							<submenu
								v-if="item.submenu.length"
								:hide-all="!showMenu"
								:item="item"
								:subitems="item.submenu"
								@hide-menu="hideMenu"
							/>
						</li>
					</ul>
					<ul v-if="page" class="lang-nav">
						<li v-for="item in page.langmenu" :key="`${item.filename}-${item.language}`">
							<nuxt-link class="flag" :class="item.language" :to="`${item.filename}`" @click="hideMenu">
								<span>{{ $t(item.language.toLowerCase()) }}</span>
							</nuxt-link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
		<div class="nav-overlay" :class="{ active: showMenu }" @click="hideMenu" />
	</div>
</template>

<script setup>
defineProps({
	items: { type: Array, default: () => [] },
});

const { page, fetchPage } = usePage();
const { locale } = useI18n();

await fetchPage();

const showMenu = ref(false);
const showBookingPopup = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const toggleMenu = () => {
	showMenu.value = !showMenu.value;
};

const hideMenu = () => {
	showMenu.value = false;
};

const toggleBookingPopup = () => {
	showBookingPopup.value = !showBookingPopup.value;
};

const hideBookingPopup = () => {
	showBookingPopup.value = false;
};
</script>

<style lang="scss" scoped>
nav {
	width: 100%;
	padding: 13px 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	transition: all 0.3s ease-in-out;
	background: var(--nav-background-color);
	backdrop-filter: blur(34px);

	.row {
		overflow: visible;
	}

	.logo {
		background: url('~/assets/images/logo-dc-hotel-restaurant-full-black.png') no-repeat center center;
		background-size: 223px;
		width: 223px;
		height: 77px;
		display: block;
	}

	&.scroll {
		.logo {
			background: url('~/assets/images/logo-dc-hotel-restaurant-small-black.png') no-repeat center center;
			background-size: 73px;
			width: 73px;
			height: 77px;
		}
	}
}

.main-nav {
	list-style: none;
	text-align: left;
	font-weight: 500;
	line-height: 26px;
	width: 100%;
	float: left;
	visibility: visible;

	& > li {
		display: block;
		float: left;
		margin: 10px 0;
		width: 100%;
	}

	a {
		color: var(--header-color);
		font-family: var(--heading-font-family);
		padding: 5px 0;
		font-weight: 700;
		font-size: 20px;
		text-decoration: none;
		transition: all 0.3s ease-in-out;
		border-bottom: 3px solid rgba(255 255 255 / 0%);

		&.active,
		&:hover,
		&.router-link-exact-active {
			color: var(--cta-color);
			border-color: var(--cta-color);
		}
	}
}

.nav-column {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
}

.lang-nav {
	list-style: none;
	text-align: left;
	float: left;
	line-height: 26px;
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;

	li {
		width: 50%;
		margin: 6px 0;
	}

	a {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		color: #000;
		gap: 10px;

		span {
			font-size: 14px;
			border-bottom: 1px solid rgba(0 0 0 / 0%);
			transition: all 0.3s ease-in-out;
		}

		&.router-link-exact-active,
		&:hover {
			color: var(--cta-color);

			span {
				border-color: var(--cta-color);
			}
		}
	}

	img {
		max-width: 22px;
		float: left;
		margin: 5px 8px 0 0;
	}
}

.nav-container {
	position: fixed;
	top: 103px;
	right: -105vw;
	bottom: 0;
	background: #fff;
	min-width: 36%;
	width: 500px;
	max-width: 100%;
	height: calc(100vh - 103px);
	padding: 75px 75px 25px;
	z-index: 100;
	box-shadow: 0 0 20px rgb(0 0 0 / 10%);
	transition: right 0.3s ease-in-out;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: flex-start;
}

.nav-overlay {
	display: none;
	position: fixed;
	inset: 103px 0 0;
	background: rgb(0 0 0 / 30%);
	z-index: 99;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	backdrop-filter: blur(14px);

	&.active {
		display: block;
		opacity: 1;
		width: 100%;
		height: calc(100vh - 103px);
	}
}

.active {
	.nav-container {
		right: 0;
		transition: right 0.5s ease-in-out;
	}
}

.booking-popup {
	position: fixed;
	inset: auto;
	margin-top: -105vh;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	background: rgba(0 0 0 / 30%);
	backdrop-filter: blur(14px);

	.booking-inner {
		background: #fff;
		padding: 40px 30px;
		max-width: 700px;
		width: 94%;

		a {
			width: 48%;
			display: block;
			opacity: 0.92;
			transition: all 0.3s ease-in-out;
			color: #fff;

			&:hover {
				opacity: 1;
			}

			span {
				display: block;
				width: 100%;
				background: var(--cta-color);
				padding: 15px;
			}
		}

		h3 {
			margin: 0 0 30px;
		}
	}

	.booking-items {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
	}

	&.active {
		margin-top: 0;
		inset: 0;
		z-index: 103;
	}
}

nav .buttons {
	.button {
		margin-top: 4px;
		margin-left: 6px;
		padding: 15px 22px 15px 17px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			margin-right: 10px;
			width: 1em;
		}
	}

	.menu-button {
		cursor: pointer;
		background: #f9f9f9;
		color: #000;
		padding: 15px 24px;

		&.active {
			color: #000;
			border-color: #000;

			i::before {
				content: '\f00d';
				font-weight: 300;
				color: #000;
			}
		}
	}
}

@media (max-height: 740px) {
	.nav-container {
		overflow-y: scroll;
	}

	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		z-index: 1;
	}

	.main-nav {
		z-index: 2;
		position: relative;
	}
}

@media (max-width: 800px) {
	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		right: auto;
		float: left;
		margin-top: 30px;
	}
}

@media (max-width: 600px) {
	.nav-container {
		padding: 40px 25px 20px;
		top: 85px;
		height: calc(100vh - 85px);
	}

	.nav-overlay {
		display: none !important;
	}

	.main-nav,
	.lang-nav {
		width: 100%;
	}

	.main-nav {
		a {
			font-size: 16px;
		}

		> li {
			margin: 7px 0;
		}
	}

	.contact-info {
		display: none;
	}

	.lang-nav {
		margin-top: 0;
	}

	nav {
		.buttons {
			.menu-button {
				padding: 14px;

				span {
					display: none;
				}

				svg {
					margin: 0;
					font-size: 20px;
				}
			}

			.button {
				&.reserve-now {
					padding: 14px;

					span {
						display: none;
					}

					svg {
						margin: 0;
						font-size: 20px;
					}
				}
			}
		}

		.logo {
			background: url('~/assets/images/logo-dc-hotel-restaurant-full-black-small.png') no-repeat center center;
			background-size: 139px;
			width: 139px;
			height: 48px;
			display: block;
		}

		&.scroll {
			.logo {
				background: url('~/assets/images/logo-dc-hotel-restaurant-full-black-small.png') no-repeat center center;
				background-size: 139px;
				width: 139px;
				height: 48px;
			}
		}
	}
}
</style>
