<template>
	<div>
		<footer>
			<div class="row">
				<div class="columns column3">
					<div class="footer-logo" />
				</div>
				<div class="columns column3 footer-contact">
					<h3>{{ $t('footerContact') }}</h3>
					<p>
						<!-- <span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span
						><br />
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span
						><br />
						<span>{{ defaults[locale].website.country }}</span
						><br />-->
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon icon="fa-light fa-phone" size="1x" />
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
				</div>
				<div class="columns column3">
					<h3>Social Media</h3>
					<social-media :socials="socials" />
				</div>
				<div class="columns column3">
					<h3>{{ $t('footerNavigate') }}</h3>
					<p class="footer-nav">
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }} <br />
						</nuxt-link>
					</p>
				</div>
			</div>

			<div class="row">
				<div class="columns column12">
					<div class="hotels-list">
						<p>
							<strong>{{ $t('ourHotels') }}: </strong>
							<span v-for="item in hotels" :key="item.id">
								<nuxt-link :to="localePath(`/${item.filename}`)">
									{{ item.header }}
								</nuxt-link>
								<span> | </span>
							</span>
						</p>
					</div>
				</div>
			</div>
		</footer>
		<footer class="author">
			<div class="row">
				<div class="columns column12">
					<div class="bottomfooter-row">
						<p class="align-left">
							<a
								href="https://www.porterforhotels.com/hotel-group-website-design"
								target="_blank"
								rel="noopener noreferrer"
								title="Hotel website design by Porter"
							>
								{{ $t('siteByButton') }}
							</a>
							<a href="https://www.porterforhotels.com/hotel-group-website-design" target="_blank">
								<img class="siteByLogo" :src="$t('siteByLogo')" loading="lazy" alt="" />
								Porter</a
							>
						</p>
						<div class="menu align-right">
							<span v-for="(item, idx) in bottomfooter[locale]" :key="item.filename">
								<nuxt-link :to="item.filename">
									{{ item.header }}
								</nuxt-link>
								<span v-if="idx < bottomfooter[locale].length - 1">|</span>
								<span v-if="defaults[locale].cookieConsent.published">
									<span> | </span>
									<a href="#" @click.prevent="openConsent">{{ $t('consentOpen') }}</a>
								</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu, bottomfooter } = footer.value;
const { locale } = useI18n();
const { openConsent } = useCookieConsent();

const { defaults, socials, fetchDefaults } = useDefaults();

const { data: hotels } = await useWebsiteFetch('hotels', {
	query: { language: locale.value },
	key: `${locale.value}/hotels`,
});

await fetchDefaults();
</script>

<style lang="scss" scoped>
footer {
	background: var(--footer-background-color);
	color: var(--body-color);
	padding: 80px 0 20px;
	font-size: 13px;

	hr {
		width: 100%;
		height: 2px;
		border: none;
		background: #fff;
		margin: 30px 0;
	}

	h3 {
		color: #fff;
		font-size: var(--h4-font-size);
		margin: 0 0 10px;
	}
}

.footer-contact {
	a {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		width: 100%;

		svg {
			margin: 0 10px 0 0;
		}
	}
}

a {
	color: var(--footer-link-color);
	text-decoration: none;

	&:hover {
		color: var(--cta-color);
	}
}

.footer-logo {
	background: url('~/assets/images/logo-dc-hotel-restaurant-full-white.png') no-repeat center center;
	width: 100%;
	max-width: 223px;
	height: 77px;
	background-size: 100%;
}

.author {
	background: var(--author-background-color);
	padding: 15px 0;
	font-size: 10px;

	p {
		margin: 0;
	}

	img {
		max-width: 20px;
		margin: 0 1px 0 2px;
	}
}

.hotels-list {
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
	padding: 12px 0;
	margin: 35px 0 0;

	p {
		margin: 0;

		strong {
			color: #dfdfdf;
			font-size: 18px;
			font-family: var(--heading-font-family);
			margin: 0 5px 0 0;
		}

		a {
			font-size: 13px;
			margin: 0 5px;
		}
	}

	span {
		&:last-child {
			span {
				display: none;
			}
		}
	}
}

.bottomfooter-row {
	display: flex;
	align-items: center;

	.menu {
		margin-left: auto;
	}
}

@media (max-width: 900px) {
	footer .columns {
		margin: 10px auto;
		text-align: left;
	}

	footer.author .columns {
		text-align: center;
	}

	footer .row:last-child .columns:last-child a {
		margin: 0;
	}
}

@media (max-width: 600px) {
	footer {
		.socials {
			align-items: center;
			justify-content: center;
		}

		.columns {
			text-align: center;

			.footer-logo {
				display: none;
			}
		}
	}

	.footer-contact a {
		justify-content: center;
	}

	.author {
		padding: 0 0 80px;
	}

	.bottomfooter-row {
		flex-flow: column-reverse wrap;
		align-items: center;

		.menu {
			margin: 0 auto 20px;
		}
	}
}
</style>
